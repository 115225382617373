import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { UsersService } from "../../account-management/users/users.service";
import { User } from "src/app/models/shared";
import { ClipboardService } from "ngx-clipboard";

@Component({
    selector: "app-aws-accounts",
    templateUrl: "./aws-accounts.component.html"
})
export class AwsAccountsComponent implements OnInit, OnDestroy {
    userAccount: User;
    pre1String: string;
    pre2String: string;

    private userAccountSubscription: Subscription;

    constructor(private us: UsersService, private cbs: ClipboardService) {}

    ngOnInit() {
        this.userAccountSubscription = this.us.user.subscribe(user => {
            this.userAccount = user;
            this.setPre();
        });
    }

    ngOnDestroy() {
        if (this.userAccountSubscription) this.userAccountSubscription.unsubscribe();
    }

    copyString(pw: string) {
        this.cbs.copy(pw);
    }

    setPre() {
        const pre1 = {
            Version: "2012-10-17",
            Statement: [
                {
                    Sid: "BroadcasterClusters",
                    Effect: "Allow",
                    Action: [
                        "ec2:DescribeKeyPairs",
                        "ec2:DescribeRegions",
                        "ec2:DescribeSecurityGroups",
                        "ec2:DescribeSubnets",
                        "ec2:DescribeVpcs",
                        "ec2:DescribeImages",
                        "ec2:DescribeInstances",
                        "ec2:DescribeTags",
                        "ec2:CreateLaunchTemplate",
                        "ec2:DeleteLaunchTemplate",
                        "ec2:CreateTags",
                        "ec2:ModifyInstanceAttribute",
                        "autoscaling:Describe*",
                        "autoscaling:TerminateInstanceInAutoScalingGroup",
                        "autoscaling:CreateAutoScalingGroup",
                        "autoscaling:DeleteAutoScalingGroup",
                        "autoscaling:UpdateAutoScalingGroup",
                        "autoscaling:CreateOrUpdateTags",
                        "autoscaling:DeleteTags",
                        "autoscaling:SuspendProcesses",
                        "autoscaling:CreateLaunchConfiguration",
                        "autoscaling:CompleteLifecycleAction",
                        "autoscaling:DeleteLifecycleHook",
                        "autoscaling:PutLifecycleHook",
                        "sqs:SendMessage",
                        "sqs:GetQueueUrl"
                    ],
                    Resource: ["*"]
                },
                {
                    Sid: "BroadcasterClustersEIPs",
                    Effect: "Allow",
                    Action: ["ec2:DescribeAddresses", "ec2:AssociateAddress", "ec2:DisassociateAddress"],
                    Resource: ["*"]
                },
                {
                    Sid: "BroadcasterClustersDPDK",
                    Effect: "Allow",
                    Action: [
                        "ec2:CreateNetworkInterface",
                        "ec2:AttachNetworkInterface",
                        "ec2:DeleteNetworkInterface",
                        "ec2:ModifyNetworkInterfaceAttribute"
                    ],
                    Resource: ["*"]
                },
                {
                    Sid: "BroadcasterClustersLifecycleSQS",
                    Effect: "Allow",
                    Action: ["iam:PassRole"],
                    Resource: ["*"],
                    Condition: {
                        StringLike: {
                            "iam:PassedToService": "autoscaling.amazonaws.com"
                        }
                    }
                },
                {
                    Sid: "MediaConnect",
                    Effect: "Allow",
                    Action: [
                        "ec2:DescribeRegions",
                        "ec2:DescribeSecurityGroups",
                        "ec2:DescribeAvailabilityZones",
                        "ec2:DescribeSubnets",
                        "cloudwatch:GetMetricData",
                        "mediaconnect:CreateFlow",
                        "mediaconnect:StartFlow",
                        "mediaconnect:StopFlow",
                        "mediaconnect:UpdateFlow",
                        "mediaconnect:UpdateFlowSource",
                        "mediaconnect:DeleteFlow",
                        "mediaconnect:ListFlows",
                        "mediaconnect:DescribeFlow",
                        "mediaconnect:AddFlowOutputs",
                        "mediaconnect:RemoveFlowOutput",
                        "mediaconnect:AddFlowVpcInterfaces",
                        "mediaconnect:RemoveFlowVpcInterface",
                        "mediaconnect:AddFlowMediaStreams",
                        "mediaconnect:UpdateFlowMediaStream",
                        "mediaconnect:RemoveFlowMediaStream",
                        "mediaconnect:ListEntitlements",
                        "mediaconnect:GrantFlowEntitlements",
                        "mediaconnect:RevokeFlowEntitlement",
                        "mediaconnect:UpdateFlowEntitlement",
                        "mediaconnect:DescribeFlowSourceThumbnail",
                        "mediaconnect:DescribeFlowSourceMetadata"
                    ],
                    Resource: ["*"]
                },
                {
                    Sid: "MediaConnectVPCInterfaces",
                    Effect: "Allow",
                    Action: ["iam:CreateServiceLinkedRole", "iam:ListRoles"],
                    Resource: ["*"]
                },
                {
                    Sid: "MediaLive",
                    Effect: "Allow",
                    Action: [
                        "ec2:DescribeRegions",
                        "ec2:DescribeSecurityGroups",
                        "medialive:listInputs",
                        "medialive:listInputDevices",
                        "medialive:listInputSecurityGroups",
                        "medialive:createInput",
                        "medialive:describeInput",
                        "medialive:describeInputDevice",
                        "medialive:describeInputDeviceThumbnail",
                        "medialive:updateInput",
                        "medialive:updateInputDevice",
                        "medialive:deleteInput",
                        "medialive:listChannels",
                        "medialive:createChannel",
                        "medialive:describeChannel",
                        "medialive:startChannel",
                        "medialive:stopChannel",
                        "medialive:updateChannel",
                        "medialive:deleteChannel"
                    ],
                    Resource: ["*"]
                },
                {
                    Sid: "MultiViewer",
                    Effect: "Allow",
                    Action: [
                        "ec2:DescribeRegions",
                        "ec2:DescribeSecurityGroups",
                        "ec2:DescribeAvailabilityZones",
                        "ec2:DescribeSubnets",
                        "ec2:DescribeTags",
                        "ec2:CreateTags",
                        "ec2:RunInstances",
                        "ec2:TerminateInstances",
                        "ec2:StartInstances",
                        "ec2:StopInstances"
                    ],
                    Resource: ["*"]
                }
            ]
        };

        const pre2 = {
            Version: "2012-10-17",
            Statement: [
                {
                    Sid: "ZenMaster",
                    Effect: "Allow",
                    Principal: {
                        AWS: [
                            `arn:aws:iam::${this.userAccount?.aws_account_id}:role/domainer`,
                            `arn:aws:iam::${this.userAccount?.aws_account_id}:role/orchestrator`
                        ]
                    },
                    Action: "sts:AssumeRole",
                    Condition: {
                        StringEquals: {
                            "sts:ExternalId": this.userAccount?.aws_external_id
                        }
                    }
                },
                {
                    Sid: "BroadcasterClustersLifecycleSQS",
                    Effect: "Allow",
                    Principal: {
                        Service: "autoscaling.amazonaws.com"
                    },
                    Action: "sts:AssumeRole"
                }
            ]
        };

        this.pre1String = JSON.stringify(pre1, undefined, 2);
        this.pre2String = JSON.stringify(pre2, undefined, 2);
    }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { map, share } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../../../constants/constants";

import { AuthService } from "src/app/services/auth.service";
import { AwsAccount } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class AwsAccountsService {
    awsAccounts: Observable<AwsAccount[]>;
    private awsAccounts$: ReplaySubject<AwsAccount[]>;
    private dataStore: {
        awsAccounts: AwsAccount[];
    };

    constructor(private authService: AuthService, private http: HttpClient, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            awsAccounts: []
        };
        this.awsAccounts$ = new ReplaySubject(1) as ReplaySubject<AwsAccount[]>;
        this.awsAccounts = this.awsAccounts$.asObservable();
    }

    private prepAwsAccount(awsAccount: AwsAccount) {
        return awsAccount;
    }

    private updateStore(awsAccount: AwsAccount, merge: boolean): void {
        this.prepAwsAccount(awsAccount);

        const currentIndex = this.dataStore.awsAccounts.findIndex(g => g.id === awsAccount.id);
        if (currentIndex === -1) {
            this.dataStore.awsAccounts.push(awsAccount);
            return;
        } else if (merge) {
            const current = this.dataStore.awsAccounts[currentIndex];

            Object.assign(current, awsAccount);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.awsAccounts[currentIndex] = awsAccount;
        }
    }

    refreshAwsAccounts(): Observable<AwsAccount[]> {
        const awsAccounts$ = this.http
            .get<{ result: AwsAccount[]; success: boolean }>(Constants.apiUrl + Constants.apiUrls.aws_accounts)
            .pipe(share());

        awsAccounts$.subscribe(
            data => {
                const awsAccounts: AwsAccount[] = data.result;

                this.dataStore.awsAccounts.forEach((existing, existingIndex) => {
                    const newIndex = awsAccounts.findIndex(tp => tp.id === existing.id);
                    if (newIndex === -1) this.dataStore.awsAccounts.splice(existingIndex, 1);
                });

                awsAccounts.forEach(tp => this.updateStore(tp, true));

                this.awsAccounts$.next(Object.assign({}, this.dataStore).awsAccounts);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        );

        return awsAccounts$.pipe(map(r => r.result));
    }

    async addAwsAccount(model: { name: string; iam_role_arn: string }): Promise<AwsAccount | false> {
        try {
            const result = await this.http
                .post<{ success: boolean; result: AwsAccount }>(
                    Constants.apiUrl + Constants.apiUrls.aws_accounts,
                    model
                )
                .toPromise();

            const awsAccount: AwsAccount = result.result;
            this.updateStore(awsAccount, false);

            this.awsAccounts$.next(Object.assign({}, this.dataStore).awsAccounts);
            return awsAccount;
        } catch (error) {
            return false;
        }
    }

    async deleteAwsAccount(awsAccount: AwsAccount | number): Promise<boolean> {
        try {
            const result = await this.http
                .delete<{ success: boolean; result: number }>(
                    Constants.apiUrl +
                        Constants.apiUrls.aws_accounts +
                        "/" +
                        `${typeof awsAccount === "number" ? awsAccount : awsAccount.id}`
                )
                .toPromise();

            const deletedId: number = result.result;

            const index = this.dataStore.awsAccounts.findIndex(a => a.id === deletedId);
            if (index !== -1) this.dataStore.awsAccounts.splice(index, 1);

            this.awsAccounts$.next(Object.assign({}, this.dataStore).awsAccounts);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateAwsAccount(
        awsAccountId: number,
        model: {
            name: string;
            iam_role_arn: string;
        }
    ): Promise<AwsAccount | false> {
        try {
            const result = await this.http
                .put<{ success: boolean; result: AwsAccount }>(
                    Constants.apiUrl + Constants.apiUrls.aws_accounts + "/" + `${awsAccountId}`,
                    model
                )
                .toPromise();

            const updatedAwsAccount: AwsAccount = result.result;
            this.updateStore(updatedAwsAccount, true);

            this.awsAccounts$.next(Object.assign({}, this.dataStore).awsAccounts);

            return updatedAwsAccount;
        } catch (error) {
            return false;
        }
    }
}
